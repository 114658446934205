import React, { useEffect, useState } from "react";

import Header from "../component/header";
import Footer from "../component/footer";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import logo from "./favicon.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
let baseUrl = process.env.REACT_APP_IN_USE_LIVE_URL;
let RzyKeyId = process.env.REACT_APP_IN_USE_RZY_KEY_IDL;
 
export default function PaymentPage() {
  const history = useHistory();
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");

  const [orderInfo, setOrderInfo] = useState(null);
  const getOrderInfo = async () => {
    try {
      let data = await axios.post(`${baseUrl}/orderInfo`, { orderId: orderId });
      if (data?.data?.success == "1") {
        setOrderInfo(data?.data?.data);
      } else {
        history.push("/");
      }
    } catch (err) {
      console.log(err);
      history.push("/");
    }
  };

  useEffect(() => {
    getOrderInfo();
  }, []);

  // console.log(orderInfo);

  const getOrderIdandAmount = async () => {
    let data = await axios.post(`${baseUrl}/get-orderId-amount`, {
      orderId,
    });
    if (data.data.success == "1") {
      handlePayment(data.data);
    }
    if (data.data.success == "0") {
      let amount = data?.data?.data?.amount ?? "";
      let payId = data?.data?.data?.paymentId ?? "";
      let status = data?.data?.data?.paymentId ?? "";
      alert(
        `Payment is ${status==="1"?"successfully":"cancelled Plz Book Again"}   ${status!=="1"?"":`paymentId ${payId}`}`
      );
      history.push("/");
    }
  };

  const clickToPay = () => {
    getOrderIdandAmount();
  };

  const handlePayment = async (data) => {
    try {
      // Request order creation from your server
      const { amount, orderId, systemOrderId ,mobileNumber} = data.data;

      // Initialize Razorpay
      let amountinPaise = parseInt(amount);

      const options = {
        // key: "rzp_test_3PPQCTixkIUlT5", // Enter the Key ID generated from the Dashboard
        key:RzyKeyId,
        amount: amountinPaise, // Amount is in currency subunits (e.g., 1000 paise = 10 INR)
        currency: "INR",
        name: "Vibrant Hospital",
        description: "Test Transaction",
        order_id: orderId,
        image: logo,
        handler: async function (response) {
          // Handle payment success
          console.log("Payment successful:", response);
          const { razorpay_payment_id, razorpay_order_id } = response;
          let dataToSend = {
            orderId,
            systemOrderId,
            razorpay_payment_id,
            razorpay_order_id,
            amount: amountinPaise,
          };

          let res = await axios.post(`${baseUrl}/payment-sucess`, dataToSend);

          history.push(
            `/payment-success?date=${
              orderInfo?.bookslot?.date ?? ""
            }&razorId=${razorpay_order_id}&paymentId=${razorpay_payment_id}&amount=${amountinPaise}&time=${
              orderInfo?.bookslot?.time ?? ""
            }&apid=${orderId}`
          );
          // You can send the payment details to your server here for further processing
        },
        prefill: {
          name: "Customer Name",
          email: "customer@example.com",
          contact: mobileNumber,
        },
        theme: {
          color: "#EF7F1A",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  function calculateDiscountedAmount(amount, discount) {
    // Convert amount and discount to numbers
    console.log(amount, discount);
    const originalAmount = parseFloat(amount);
    const discountPercentage = parseFloat(discount);

    // Validate input
    if (isNaN(originalAmount) || isNaN(discountPercentage)) {
      throw new Error("Invalid amount or discount value");
    }

    if (discountPercentage < 0 || discountPercentage > 100) {
      throw new Error("Discount should be between 0 and 100");
    }

    // Calculate discounted amount
    const discountedAmount =
      originalAmount - (originalAmount * discountPercentage) / 100;

    // Format to two decimal places and convert to a number
    return parseFloat(discountedAmount.toFixed(2));
  }
  return (
    <>
      <Header />
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10"
            style={{ margin: "5% 0 10% 0" }}
          >
            <h1 className="a1" style={{ paddingBottom: ".2rem" }}>
              Consultation Summary
            </h1>
            <p className="a2">
              Please verify the details before proceeding to the payment page.
            </p>
            <dl className="row payment_detail">
              {orderInfo?.serviceDetail ? (
                <>
                  <dt className="col-sm-3">Service</dt>
                  <dd className="col-sm-9">
                    {orderInfo?.serviceDetail?.Name ?? ""}
                  </dd>
                </>
              ) : (
                <>
                  <dt className="col-sm-3">Doctor Name</dt>
                  <dd className="col-sm-9">
                    {" "}
                    {orderInfo?.docDetail?.name ?? ""}
                  </dd>
                  <dt className="col-sm-3">Speciality</dt>
                  <dd className="col-sm-9">
                    {" "}
                    {orderInfo?.docDetail?.Speciality.map((e) => e, +" ") ?? ""}
                  </dd>
                </>
              )}

              <dt className="col-sm-3">Patient Name</dt>
              <dd className="col-sm-9">
                {orderInfo?.user?.name ?? ""} {orderInfo?.user?.lastName ?? ""}
              </dd>
              <dt className="col-sm-3">Contact Number</dt>
              <dd className="col-sm-9">{orderInfo?.user?.phoneNumber}</dd>
              <dt className="col-sm-3">Booking on</dt>
              <dd className="col-sm-9">
                {orderInfo?.bookslot?.date
                  ? (() => {
                      const originalDate = moment(orderInfo?.bookslot?.date);
                      const formattedDate = originalDate.format("MMMM D, YYYY");
                      return formattedDate + " " + orderInfo?.bookslot?.time;
                    })()
                  : ""}
              </dd>
              <dt className="col-sm-3">Appointment ID</dt>
              <dd className="col-sm-9">{orderId}</dd>
              <dt className="col-sm-3">
                {orderInfo?.bookslot?.amountPaid ? (
                  <>Fee Paid </>
                ) : (
                  <>Fee to be Paid</>
                )}
              </dt>
              <dd className="col-sm-9">
                <span className="a5" style={{ color: "#f07404" }}>
                  {orderInfo?.bookslot?.amountPaid ? (
                    orderInfo?.bookslot?.amountPaid
                  ) : (
                    <>
                     <span className="text-decoration-line-through text-muted">
                            ₹ {orderInfo?.docDetail?.AppointmentFee}
                          </span>
                      ₹{" "}
                      {orderInfo?.docDetail?.AppointmentFee &&
                      orderInfo?.docDetail?.AppointmentFee !== undefined ? (
                        <>
                          {calculateDiscountedAmount(
                            orderInfo?.docDetail?.AppointmentFee,
                            orderInfo?.docDetail?.discount ?? 0
                          )}{" "}
                         
                        </>
                      ) : (
                        "...Loading"
                      )}
                      {orderInfo?.serviceDetail?.Price ?? ""}
                    </>
                  )}
                </span>
              </dd>

              {orderInfo?.bookslot?.paymentId &&
              orderInfo?.bookslot?.amountPaid ? (
                <>
                  <dt className="col-sm-3">PaymentId</dt>
                  <dd className="col-sm-9">{orderInfo?.bookslot?.paymentId}</dd>
                </>
              ) : (
                ""
              )}
            </dl>
            {orderInfo?.bookslot?.paymentId &&
            orderInfo?.bookslot?.amountPaid ? (
              <></>
            ) : (
              <button
                className="btn btn-secondary btn-special-cta"
                onClick={clickToPay}
              >
                Pay Now
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
